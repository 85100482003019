import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';
import marker from '../images/pin.png';
import "leaflet/dist/leaflet.css";

export default class MarkerPinPerson extends React.Component {
    render() {
        if (typeof window !== "undefined") {
            const myIcon = new L.Icon({
              iconUrl: marker,
              iconRetinaUrl: marker,
              popupAnchor:  [-0, -25],
              iconSize: [50,65],
            });
            return (
                <MapContainer style={{ height: '500px' }} center={[13.75, 100.50]} zoom={10} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[13.76, 100.55]} icon={myIcon}>
                        <Popup>
                        1/4 ซ.ทินกร ถนนดินแดง แขวงดินแดง เขตดินแดง กทม. 10400
                        <br/>
                        <a href="https://goo.gl/maps/t4U5prSnL2KBqCUu6" target="_blank" rel="noreply noreferrer">คลิกเพื่อเปิดแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.6323864,100.5035876]} icon={myIcon}>
                        <Popup>
                        326/67 ซอยประชาอุทิศ 87 ถนนประชาอุทิศ แขวงทุ่งครุ เขตทุ่งครุ กรงเทพฯ 10140
                        <br/>
                        <a href="https://goo.gl/maps/QXHSg5qucBCrHkiSA" target="_blank" rel="noreply noreferrer">คลิกเพื่อเปิดแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.633161,100.426599]} icon={myIcon}>
                        <Popup>
                        ซ. สะแกงาม 35/2 แยก 1
                        <br/>
                        <a href="https://goo.gl/maps/h4vYMpJaW2xadQye7" target="_blank" rel="noreply noreferrer">คลิกเพื่อเปิดแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.6728993,100.5128053]} icon={myIcon}>
                        <Popup>
                        วัดสน
                        <br/>
                        <a href="https://goo.gl/maps/B4ai5srscsps7msDA" target="_blank" rel="noreply noreferrer">คลิกเพื่อเปิดแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.7599924,100.5590969]} icon={myIcon}>
                        <Popup>
                        9/401 ตลาดขวัญพัฒนา ดินแดง
                        <br/>
                        <a href="https://goo.gl/maps/K3RuQMmfoHs2HExWA" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.7065905,100.3229803]} icon={myIcon}>
                        <Popup>
                        219/193-4 หมู่บ้านพงษ์ศิริชัย 1 ถนนเพชรเกษม ต.อ้อมน้อย อ.กระทุ่มแบน จ.สมุทรสาคร 74130
                        <br/>
                        <a href="https://maps.app.goo.gl/FLLMXDipeaezaC696" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.7021941,100.3206052]} icon={myIcon}>
                        <Popup>
                        74/52 เวทีมอยอ้อมน้อย
                        <br/>
                        <a href="https://goo.gl/maps/nXRuyzmjP6521T8V8" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.7021941,100.3206052]} icon={myIcon}>
                        <Popup>
                        99 ถ.รามคำแหง ซ. 203/1 เขตมีนบุรี กทม. 16510
                        <br/>
                        <a href="https://goo.gl/maps/ZQP7chEPamjAx5i79" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.9065575,100.5043289]} icon={myIcon}>
                        <Popup>
                        79/36-37 หมู่บ้านเกร็ดแก้วการ์เด้น 2 ถ.ติวานนท์ ปากเกร็ด นนทบุรี 11120
                        <br/>
                        <a href="https://goo.gl/maps/AVW9yYaJ6q3t51EFA" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.7804367,100.5682255]} icon={myIcon}>
                        <Popup>
                        ประชาสงเคราะห์ ซ.26 ห้วยขวาง
                        <br/>
                        <a href="https://goo.gl/maps/nDD6x5bor7qcy5cn6" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.6586921,100.3439979]} icon={myIcon}>
                        <Popup>
                        45/1907-8 ม.ดีเค ซ.22 บางบอน กทม.
                        <br/>
                        <a href="https://goo.gl/maps/PP8hx7ymFjKaEz5Q6" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.6765082,100.4126436]} icon={myIcon}>
                        <Popup>
                        179/6-7 ถ.กัลปพฤก เอกชัย 76 บางแค กรุงเทพ 10160
                        <br/>
                        <a href="https://goo.gl/maps/PP8hx7ymFjKaEz5Q6" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.6514771,100.4063952]} icon={myIcon}>
                        <Popup>
                        45/127 หมู่บ้าน DK ถ.กาญจนภิเษก บางบอน บางบอน กรุงเทพฯ 10150
                        <br/>
                        <a href="https://goo.gl/maps/PP8hx7ymFjKaEz5Q6" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.7096562,100.5991215]} icon={myIcon}>
                        <Popup>
                        1973/1-2 ปากซ.สุขุมวิท 75 พระโขนงเหนือ วัฒนา กรุงเทพฯ
                        <br/>
                        <a href="https://goo.gl/maps/EisXgShgQ1nepZev7" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.7750024,100.5487861]} icon={myIcon}>
                        <Popup>
                        4669/36 ติดโรงรับจำนำ ถ.ประชาสงเคราะห์ แขวงดินแดง เขตดินแดง กรุงเทพฯ 10400
                        <br/>
                        <a href="https://goo.gl/maps/KmS2qpTd3ERbfYnR7" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[14.0416211,100.6651219]} icon={myIcon}>
                        <Popup>
                        40/3 หมู่บ้านพฤกษา B ถ.เรียบคลอง 3 คลอง 3 คลองหลวง ปทุมธานี 12120
                        <br/>
                        <a href="https://goo.gl/maps/ZuGnuDcaehLCu5HU9" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.8671652,100.6661103]} icon={myIcon}>
                        <Popup>
                        18/197 รามอินทรา 71 ซ.สยามธรณ์ ท่าแร่ง บางเขน
                        <br/>
                        <a href="https://goo.gl/maps/JQvMt9wEoxVpxcPH7" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.6710834,100.3747108]} icon={myIcon}>
                        <Popup>
                        45/661 หมู่บ้าน DK ซ,10 แขวงบางบอน เขตบางบอน กรุงเทพฯ 10160
                        <br/>
                        <a href="https://goo.gl/maps/tTGPbfrR1ioduAh98" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                    <Marker position={[13.7122486,100.2552375]} icon={myIcon}>
                        <Popup>
                        25/7-8 ม.4 ข้างตึกเฟิร์สเลดิ์ ถ.เพชรเกษม สามพราน นครปฐม 73160
                        <br/>
                        <a href="https://goo.gl/maps/FdxH34dbs2yZ4kBg8" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่</a>
                        </Popup>
                    </Marker>
                </MapContainer>
            )
        }
        return null
    }
 }