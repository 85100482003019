import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import * as footerStyles from "./footer.module.css"
import { Icon } from '@iconify/react';
import lineIcon from '@iconify/icons-cib/line';
import facebookIcon from '@iconify/icons-brandico/facebook';

const facebook = "https://www.facebook.com/ผลิตและจำหน่าย-ด้ายเย็บกระสอบ-ด้ายการ์เม้นท์และด้ายอุตสาหกรรม-ทุกชนิด-101655694919457/";
const line = "https://lin.ee/7y11wvo";

export default function Formfooter () {
    return (
        <div className={footerStyles.fontp}>
        <Container fluid="xl">
            <Row>
                <Col md={3} xs={12}><p className={footerStyles.fp}><b>สนใจสินค้า</b> ฝากชื่อเบอร์โทรให้เราติดต่อกลับ</p></Col>
                <Col md={6} xs={12}>
                <form id="phoneform" method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                    <ul className={footerStyles.bulletsno}>
                        <li className={footerStyles.lipaddingsocial}><input className={footerStyles.fform} type="text" placeholder="เบอร์โทรศัพท์" name="เบอร์โทรศัพท์" required /></li>
                        <li className={footerStyles.lipaddingsocial}><input className={footerStyles.fform} type="text" placeholder="ชื่อ-นามสกุล" name="ชื่อ-นามสกุล" required /></li>
                        <li className={footerStyles.lipaddingsocial}><button className={footerStyles.fbuttom} type="submit">ส่ง</button></li>
                    </ul>
                </form>
                </Col>
                <Col md={3} xs={12} className={footerStyles.fp}>
                    <ul className={footerStyles.bulletsnoa}>
                        <li className={footerStyles.lipaddingsocial}>ช่องทางติดต่อเรา</li>
                        <li className={footerStyles.lipaddingsocial} style={{ color:"#3B5998" }}><a className="facebook" aria-label="facebook" href={facebook} target="_blank" rel="noreferrer" style={{ color:"#3B5998" }}><Icon icon={facebookIcon} height={24} /></a></li>
                        <li className={footerStyles.lipaddingsocial} style={{ color:"#00b900" }}><a className="line" aria-label="line" href={line} target="_blank" rel="noreferrer" style={{ color:"#00b900" }}><Icon icon={lineIcon} height={25} /></a></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </div>
    )
}