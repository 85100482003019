import React from "react";
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import Seo from "../components/seo"
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import styled from "styled-components";
import Map from "../components/map"
import { Icon } from '@iconify/react'
import arrowRight from '@iconify/icons-akar-icons/arrow-right'


const HeroWrapper = styled.div`
  h1 {
    font-size: 36px;
  }
  p {
    color: #838383;
  }
`

const ClientWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .distributorCard {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid #333;
    p {
      margin-bottom: 0;
    }
    .linkMap {
      margin-top: 10px;
      margin-bottom: 10px;
      a {
        text-decoration: underline;
        color: #8897A2;
        font-weight: 400;
        :hover {
          color: #007bff;
        }
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    .distributorCard {
      width: 33%;
      border-bottom: 0px;
    }
  }
`


const Seotitle = "ตัวแทนจำหน่ายของเรา"
const seoDescription = "ตัวแทนจำหน่าย เส้นด้ายอุตสาหกรรม ทุกชนิด สามารถดูสินค้าของเรา ทรงไทยเท็กซ์ไทล์ ได้ตามพื้นที่ต่างๆได้แล้ววันนี้"

const Distributor = () => {
  return (
    <Containers>
      <Seo 
        title={Seotitle} 
        description={seoDescription}
      />
      <Headertop />
      <Header />
        <div>
          <Map />
        </div>
        <Container className="pb-5">
          <Row>
            <Col md={12} className="pt-5">
              <HeroWrapper>
                <h1>ตัวแทนจำหน่าย</h1>
                <p>ดูตัวอย่างสินค้าได้ตามโซนพื้นที่ต่างๆ ที่เป็นร้านตัวแทนของเรา</p>
              </HeroWrapper>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        โซน ภาคกลาง
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <ClientWrapper>
                          <div className="distributorCard">
                            <p>1/4 ซ.ทินกร ถนนดินแดง แขวงดินแดง เขตดินแดง กทม. 10400</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/t4U5prSnL2KBqCUu6" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>326/67 ซอยประชาอุทิศ 87 ถนนประชาอุทิศ แขวงทุ่งครุ เขตทุ่งครุ กรงเทพฯ 10140</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/QXHSg5qucBCrHkiSA" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>ซ. สะแกงาม 35/2 แยก 1</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/h4vYMpJaW2xadQye7" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>วัดสน</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/B4ai5srscsps7msDA" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>9/401 ตลาดขวัญพัฒนา ดินแดง</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/K3RuQMmfoHs2HExWA" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>219/193-4 หมู่บ้านพงษ์ศิริชัย 1 ถนนเพชรเกษม ต.อ้อมน้อย อ.กระทุ่มแบน จ.สมุทรสาคร 74130</p>
                            <div className="linkMap">
                              <a href="https://maps.app.goo.gl/FLLMXDipeaezaC696" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>74/52 เวทีมอยอ้อมน้อย</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/nXRuyzmjP6521T8V8" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>99 ถ.รามคำแหง ซ. 203/1 เขตมีนบุรี กทม. 16510</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/ZQP7chEPamjAx5i79" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>79/36-37 หมู่บ้านเกร็ดแก้วการ์เด้น 2 ถ.ติวานนท์ ปากเกร็ด นนทบุรี 11120</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/AVW9yYaJ6q3t51EFA" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>ประชาสงเคราะห์ ซ.26 ห้วยขวาง</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/nDD6x5bor7qcy5cn6" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>45/1907-8 ม.ดีเค ซ.22 บางบอน กทม.</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/PP8hx7ymFjKaEz5Q6" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>179/6-7 ถ.กัลปพฤก เอกชัย 76 บางแค กรุงเทพ 10160</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/wLCiwpaYDEUV47nn9" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>45/127 หมู่บ้าน DK ถ.กาญจนภิเษก บางบอน บางบอน กรุงเทพฯ 10150</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/tPt1k1k9m3d7qoQi9" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>1973/1-2 ปากซ.สุขุมวิท 75 พระโขนงเหนือ วัฒนา กรุงเทพฯ</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/EisXgShgQ1nepZev7" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>4669/36 ติดโรงรับจำนำ ถ.ประชาสงเคราะห์ แขวงดินแดง เขตดินแดง กรุงเทพฯ 10400</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/KmS2qpTd3ERbfYnR7" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>40/3 หมู่บ้านพฤกษา B ถ.เรียบคลอง 3 คลอง 3 คลองหลวง ปทุมธานี 12120</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/ZuGnuDcaehLCu5HU9" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>18/197 รามอินทรา 71 ซ.สยามธรณ์ ท่าแร่ง บางเขน</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/JQvMt9wEoxVpxcPH7" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>45/661 หมู่บ้าน DK ซ,10 แขวงบางบอน เขตบางบอน กรุงเทพฯ 10160</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/tTGPbfrR1ioduAh98" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                          <div className="distributorCard">
                            <p>25/7-8 ม.4 ข้างตึกเฟิร์สเลดิ์ ถ.เพชรเกษม สามพราน นครปฐม 73160</p>
                            <div className="linkMap">
                              <a href="https://goo.gl/maps/FdxH34dbs2yZ4kBg8" target="_blank" rel="noreply noreferrer">คลิกเพื่อดูแผนที่ <Icon icon={arrowRight} /></a>
                            </div>
                          </div>
                        </ClientWrapper>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        โซน อื่นๆ
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>ไม่พบข้อมูล</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      <Formfooter />
      <Footer />
    </Containers>
    );
}

export default Distributor;